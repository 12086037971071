;
@import "00-settings/index";
@import "01-tools/index";
@import "02-generic/index";
@import "03-components/index";
@import "04-modules/index";
@import "05-rtl/index";

@import "bootstrap/scss/bootstrap";

$brandPrimaryColor: #ffffff;
$brandSecondaryColor: #cccccc;
$backgrounDefaultColor: #ffffff;
$textDefaultColor: #333333;
$bodyFont: Arial;
$displayFont: Impact;
$borderRadius: 4;
$iconStyle: Line;
$categoryColor: #000;
$expandedCategoryColor: #000;

:root {
    --brandPrimaryColor: #{brandPrimaryColor};
    --brandSecondaryColor: #{brandSecondaryColor};
    --backgroundDefaultColor: #{$backgrounDefaultColor};
    --textDefaultColor: #{textDefaultColor};
    --bodyFont: #{bodyFont};
    --displayFont: #{displayFont};
    --borderRadius: #{borderRadius};
    --iconStyle: #{iconStyle};
    --categoryColor: #{categoryColor};
    --expandedCategoryColor: #{expandedCategoryColor};
}

// peruvian customizations begin

@font-face {
    font-family: "GillSansPeruvian-Book";
    src: url("../../../GillSansPeruvian-Book.eot");
    src: url("../../../GillSansPeruvian-Book.eot") format("embedded-opentype"),
        url("../../../GillSansPeruvian-Book.woff") format("woff"),
        url("../../../GillSansPeruvian-Book.ttf") format("truetype"),
        url("../../../GillSansPeruvian-Book.svg#GillSansPeruvian-Book") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "GillSansPeruvian-Bold";
    src: url("../../../GillSansPeruvian-Bold.eot");
    src: url("../../../GillSansPeruvian-Bold.eot") format("embedded-opentype"),
        url("../../../GillSansPeruvian-Bold.woff") format("woff"),
        url("../../../GillSansPeruvian-Bold.woff2") format("woff2"),
        url("../../../GillSansPeruvian-Bold.ttf") format("truetype"),
        url("../../../GillSansPeruvian-Bold.svg#GillSansPeruvian-Bold") format("svg");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "GillSansPeruvian-Medium";
    src: url("../../../gillsansperuvian-medium.eot");
    src: url("../../../gillsansperuvian-medium.eot") format("embedded-opentype"),
        url("../../../gillsansperuvian-medium.woff") format("woff"),
        url("../../../gillsansperuvian-medium.ttf") format("truetype"),
        url("../../../gillsansperuvian-medium.svg#GillSansPeruvian-Medium") format("svg");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "GillSansPeruvian-Light";
    src: url("../../../gillsansperuvian-light.eot");
    src: url("../../../gillsansperuvian-light.eot") format("embedded-opentype"),
        url("../../../gillsansperuvian-light.woff") format("woff"),
        url("../../../gillsansperuvian-light.ttf") format("truetype"),
        url("../../../gillsansperuvian-light.svg#GillSansPeruvian-Light") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "UglyQua";
    src: url("../../../uglyqua-webfont.eot");
    src: url("../../../uglyqua-webfont.eot") format("embedded-opentype"),
        url("../../../uglyqua-webfont.woff") format("woff"), url("../../../uglyqua-webfont.woff2") format("woff2"),
        url("../../../uglyqua-webfont.ttf") format("truetype"),
        url("../../../uglyqua-webfont.svg#UglyQua") format("svg");
    font-weight: normal;
    font-style: normal;
}

// D365 Launch 2022
@font-face {
    font-family: 'modernline';
    src: url('../../../modernline-webfont.eot');
    src: url('../../../modernline-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../../modernline-webfont.woff') format('woff'),
         url('../../../modernline-webfont.woff2') format('woff2'),
         url('../../../modernline-webfont.ttf') format('truetype'),
         url('../../../modernline-webfont.svg#modernline') format('svg');
    font-weight: normal;
    font-style: normal;
}

// Gift 2022
@font-face {
    font-family: 'Angelic Bright';
    src: url('../../../Angelic Bright.eot');
    src: url('../../../Angelic Bright.eot?#iefix') format('embedded-opentype'),
         url('../../../Angelic Bright.woff') format('woff'),
         url('../../../Angelic Bright.woff2') format('woff2'),
         url('../../../Angelic Bright.ttf') format('truetype'),
         url('../../../Angelic Bright.svg#Angelic Bright') format('svg');
    font-weight: normal;
    font-style: normal;
}

// PCxGB 2022
@font-face {
    font-family: 'QueensCondensed-Regular';
    src: url('../../../QueensCondensed-Regular.eot');
    src: url('../../../QueensCondensed-Regular.eot?#iefix') format('embedded-opentype'),
         url('../../../QueensCondensed-Regular.woff') format('woff'),
         url('../../../QueensCondensed-Regular.woff2') format('woff2'),
         url('../../../QueensCondensed-Regular.ttf') format('truetype'),
         url('../../../QueensCondensed-Regular.svg#QueensCondensed-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

// FALL 2022
@font-face {
    font-family: 'Apothecary';
    src: url('../../../Apothecary.eot');
    src: url('../../../Apothecary.eot?#iefix') format('embedded-opentype'),
         url('../../../Apothecary.woff') format('woff'),
         url('../../../Apothecary.woff2') format('woff2'),
         url('../../../Apothecary.ttf') format('truetype'),
         url('../../../Apothecary.svg#Apothecary') format('svg');
    font-weight: normal;
    font-style: normal;
}

// SPRING 2022
@font-face {
    font-family: 'belluga-slanted';
    src: url('../../../Bellugaslanted.eot');
    src: url('../../../Bellugaslanted.eot?#iefix') format('embedded-opentype'),
         url('../../../Bellugaslanted.woff') format('woff'),
         url('../../../Bellugaslanted.woff2') format('woff2'),
         url('../../../Bellugaslanted.ttf') format('truetype'),
         url('../../../Bellugaslanted.svg#belluga-slanted') format('svg');
    font-weight: normal;
    font-style: normal;
}

// WINTER 2021
@font-face {
    font-family: 'made-mirage-regular';
    src: url('../../../MADE-Mirage-Regular.eot');
    src: url('../../../MADE-Mirage-Regular.eot?#iefix') format('embedded-opentype'),
         url('../../../MADE-Mirage-Regular.woff') format('woff'),
         url('../../../MADE-Mirage-Regular.woff2') format('woff2'),
         url('../../../MADE-Mirage-Regular.ttf') format('truetype'),
         url('../../../MADE-Mirage-Regular.svg#made-mirage-regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

// SPRING 2019
@font-face {
    font-family: 'AvenirNext-UltraLight';
    src: url('../../../AvenirNext-UltraLight-webfont.eot');
    src: url('../../../AvenirNext-UltraLight-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../../AvenirNext-UltraLight-webfont.woff') format('woff'),
         url('../../../AvenirNext-UltraLight-webfont.woff2') format('woff2'),
         url('../../../AvenirNext-UltraLight-webfont.ttf') format('truetype'),
         url('../../../AvenirNext-UltraLight-webfont.svg#AvenirNext-UltraLight') format('svg');
    font-weight: normal;
    font-style: normal;
}

// FALL 2018
@font-face {
    font-family: 'cormorant_garamondregular';
    src: url('../../../cormorantgaramond-regular-webfont.eot');
    src: url('../../../cormorantgaramond-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../../cormorantgaramond-regular-webfont.woff') format('woff'),
         url('../../../cormorantgaramond-regular-webfont.woff2') format('woff2'),
         url('../../../cormorantgaramond-regular-webfont.ttf') format('truetype'),
         url('../../../cormorantgaramond-regular-webfont.svg#cormorant_garamondregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

header {
    background: var(--backgroundDefaultColor);
}

main {
    background: var(--backgroundDefaultColor);

    @media (max-width: $msv-breakpoint-m) {
        margin-top: 20%;
    }
}

.row {
    margin: 0;
}

// main{
// 	padding: 0px 40px;
// }

// peruvian customizations end

body {
    background-color: var(--backgroundDefaultColor);
    font-family: "GillSansPeruvian-Book", Helvetica, Arial, sans-serif; /* peruvian customization */
    letter-spacing: 0.03em; /* peruvian customization */
}

a:hover {
    color: inherit;
    text-decoration: none;
}

.msc-cart_btn-savebag {
    font-family: "GillSansPeruvian-Book", Helvetica, Arial, sans-serif; /* peruvian customization */
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    text-transform: none;
    height: 48px;
    text-align: center;
    background-color: var(--msv-cart-secondary-btn-bg);
    border: 1px solid var(--msv-cart-secondary-btn-border);
    color: var(--msv-cart-secondary-btn-font-color);
    padding: 12px 20px;
    min-width: 160px;
    margin: 15px auto;
    width: 100%;
}

.cus-search-order {
    display: flex;
    flex-wrap: wrap;
}
.cus-search-order {
    display: flex;
    flex-wrap: wrap;
}
.cus-search-order-heading {
    font-family: "GillSansPeruvian-Book", Helvetica, Arial, sans-serif; /* peruvian customization */
    font-weight: var(--msv-font-weight-normal);
    font-size: var(--msv-cart-heading-font-size);
    line-height: 31px;
    text-transform: none;
    color: var(--msv-cart-heading-font-color);
    flex: 0 0 100%;
    margin-bottom: 20px;
    margin-top: 32px;
}
.cus-search-order-detail-wraper {
    border-top: 1px solid var(--msv-cart-border);
    box-sizing: border-box;
    padding-top: 20px;
    letter-spacing: 1px;
    flex: 1;
    margin-bottom: 1.5rem;
    margin-right: 40px;
    color: #58585b;
    font-size: 1.2em;
    font-weight: normal;
    display: block;
    line-height: 25px;
    form {
        margin-top: 19px;
        margin-bottom: 10px;
    }
}
.msc-cart-line__product-price-actual .msc-price__strikethrough,
.msc-cart-line__product-savings-actual .msc-price__strikethrough {
    display: block !important;
}

.cus-search-bar-sug-left {
    /*position: relative;
    display: inline-block;*/
    min-width: 0px;
    float: left;
    max-width: 300px;
}
.cus-search-bar-sug-right {
    /* position: relative;
    display: inline-block;
     position: relative;
    */
    min-width: 0px;
    float: left;
    max-width: 300px;
}

.cus-white-spaces {
    white-space: pre-line;
}

.cus-search-bar-sug-right {
    margin-top: auto;
    img {
        max-width: 100%;
    }
}
.ms-search__autoSuggest {
    max-width: 600px;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.msc-cart-line__product-price-actual .msc-price__strikethrough,
.msc-cart-line__product-savings-actual .msc-price__strikethrough {
    display: block !important;
}

.cus-select-popup {
    display: inline-block;
    padding: 3px 10px;
    border: 1px solid gray;
}

.custom-swatches.color-swatch {
    display: flex;
}

.custom-swatches.color-swatch li button {
    width: 40px;
    height: 40px;
    border: 1px solid #e2e2e2;
    margin-right: 5px;
}

.custom-swatches.color-swatch li.selected button {
    box-shadow: 0 0 0 1px #000;
    border: 1px solid #fff !important;
    color: #fff !important;
    // width: 40px;
}
.ms-search__form-control {
    max-width: 504px;
}

.tooltip-container {
    position: relative;
    .cus-tooltip {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        div {
            border: #c0c0c0 1px solid;
            display: block;
            z-index: 10;
            left: -22px;
            position: absolute;
            top: -40px;
            text-decoration: none;
            min-width: 80px;
            background: #fff;
            color: #000;
            padding: 0 5px;
            white-space: nowrap;
        }
    }
}
.cust-accordion {
    // margin-right: 12px;
    margin-top: 25px;
    max-width: 358px;
    .accordion-btn {
        text-align: left;
        span {
            float: right;
        }
        &::after {
            content: unset;
        }
    }
    .collapse {
        overflow: hidden;
        padding: 15px;
        padding-top: 0;
        ul.uol {
            // list-style-type: circle;
            list-style: none;
        }
        ul li::before {
            content: " •";
            color: black;
            display: inline-block;
            width: 2px;
            margin-left: -2px;
        }
        ul li a {
            margin-left: 7px;
            &:hover {
                cursor: pointer;
                text-decoration: underline;
            }
        }

        .cus-link {
            margin-top: 5px;
            &::before {
                content: " •";
                color: black;
                display: inline-block;
                width: 2px;
                margin-right: 5px;
            }
            &:hover {
                cursor: pointer;
                text-decoration: underline;
                color: blue($color: #000000);
            }
        }
    }
}

.ms-buybox__quantity {
    margin-top: 20px;
}
*,
*::before,
*::after {
    /* box-sizing: border-box; */
}
user agent stylesheet div {
    display: block;
}

.ms-buybox .product-add-to-cart {
    margin-top: 20px;

    *,
    *::before,
    *::after {
        box-sizing: border-box;
    }
    user agent stylesheet div {
        display: block;
    }
}

.cust-search-order-linklbl {
    float: right;
    margin-right: 75px;
    margin-top: 10px;
    font-family: "GillSansPeruvian-Book", Helvetica, Arial, sans-serif; /* peruvian customization */
    font-weight: var(--msv-font-weight-normal);
    font-size: var(--msv-header-font-size);
    line-height: 21px;
    text-transform: none;
}

.cust-search-order-linklbl a:hover {
    text-decoration: none;
    color: #000000;
}

.ms-buybox .product-add-to-cart .buttons {
    display: grid;
    grid-template-columns: auto 56px;
}
.ms-buybox .product-add-to-cart .msc-add-to-cart {
    width: 100% !important;
}

// .btn-xl .btn-sm, .btn-xs  ,.btn-lg {
//     height: 20px;

// }

.form-inline .msc-btn {
    height: unset !important;
    padding: 5px 45px !important;
}

.checkout-checkbox {
    margin-top: 7px;
}

.msc-swatch-container__item {
    min-width: 18;
    min-height: 18px;
}

.popup-message {
    background-color: lightgrey;
    padding: 7px;
    width: 100%;
    margin: 10px 0;
    transition: 0.3s;
    display: none;
}

.cus-ms-parent-popup:last-child {
    .popup-message {
        display: block;
    }
}

body > iframe {
    display: none !important;
    z-index: -1 !important;
} 