$msv-breakpoint-xs: 0;
$msv-breakpoint-s: 0;
$msv-breakpoint-landscape-s: 736px; /* peruvian customization */
$msv-breakpoint-landscape-m: 812px; /* peruvian customization */
$msv-breakpoint-m: 768px;
$msv-breakpoint-l: 992px;
$msv-breakpoint-xl-peruvian: 1200px; /* peruvian customization */
$msv-breakpoint-xl: 1440px;
$msv-breakpoint-xxl: 1600px; /* peruvian customization */
$msv-breakpoint-xxl-plp: 1650px; /* peruvian customization */

$msv-gutter-width-s: 16px;
$msv-gutter-width-m: 20px;
$msv-gutter-width-l: 60px;

$msv-container-max-width-s: 768px;
$msv-container-max-width-m: 992px;
$msv-container-max-width-l: 1440px;

$msv-container-min-width-m: 769px;
