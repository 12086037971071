.ms-guest-checkout-on-account-order-info {
    margin: 20px 0;
}
// .ms-guest-checkout-on-account-thank-you {
//     text-align: center;
//     margin: 50px 0;
//     h2 {
//         font-family: $msv-primary-font-family;
//         font-size: $msv-title-font-size-xs;
//         color: $msv-text-color;
//         margin: 20px 0;
//     }
//     h3 {
//         font-family: $msv-primary-font-family;
//         font-size: $msv-title-font-size-xs;
//         color: $msv-text-color;
//         margin: 20px 0;
//     }
//     h4 {
//         font-family: $msv-primary-font-family;
//         font-size: $msv-title-font-size-xs;
//         color: $msv-text-color;
//         margin: 20px 0;
//     }
// }