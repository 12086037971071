//spacing
$header-container-spacing-desktop: 40px;
$header-container-spacing-mobile-left: 6px;
$header-container-spacing-mobile-right: 6px; /* peruvian customization */
$header-container-spacing-mobile: 0;
$signin-info-spacing-left: 2px;
$navigation-spacing-left: 8px;
$cart-icon-spacing-left: 20px;
$heading-padding-top: 25px;
$signin-info-padding: 12px 14px;
$header-height: 48px;
$header-mobile-height: auto; /* peruvian customization */
$header-icon-width: 48px;
$account-link-height: 35px;
$account-links-max-width: 276px;
$signin-link-padding: 0 10px;
$signin-link-height: 48px;
$header_container-max-width: $msv-breakpoint-xl;
$header-myaccount-margin-left: 8px;
// $header-logo-margin-right: 20px; /* peruvian customization */
// peruvian customization - removed header-logo-margin
$header-icon-padding-right: 10px;
$header-icon-padding-left: 10px;
$header-cart-icon-margin-left: 4px;
$header-list-item-link-padding: 0 20px;
$popover-menu-item-height: 48px;
$msv-default-container-padding-bottom: 25px;
$divider-margin-left: 8px;
$divider-margin-right: 8px;
$divider-margin-width: 1px;
$divider-margin-height: 20px;
$divider-padding-m: 10px 0;
$nav-padding-top-m: 20px;
$header-store-selector-btn-padding: 12px 10px;

//style presets
:root {
    --msv-header-bg: var(--msv-bg-color);
    --msv-header-border: var(--msv-border-color);
    --msv-header-font-color: var(--msv-font-primary-color);
    --msv-header-font-size: var(--msv-body-font-size-m);
}

header {
    background-color: var(--msv-header-bg);
}

.ms-header {
    background-color: var(--msv-header-bg);
    color: var(--msv-header-font-color);
    // border: 1px solid var(--msv-header-border);

    margin-left: auto;
    margin-right: auto;
    padding-left: $header-container-spacing-mobile;
    padding-right: $header-container-spacing-mobile;
    padding-top: 10px;
    background: url("https://images-us-prod.cms.commerce.dynamics.com/cms/api/pdzhjcgxml/imageFileData/MA18eS?ver=950a") repeat-x; /* peruvian customization */
    background-position: 0 40%; /* peruvian customization */

    &__container {
        padding-bottom: $msv-default-container-padding-bottom;

        .ms-header__logo {

            // peruvian customization - removed margin-right

            a {
                @include vfi();
                display: block;

                &:focus {
                    outline-offset: -2px;
                }
            }

            // peruvian customization
            img {
                margin: 0 auto;
                height: auto;
            }
        }

        .ms-header__collapsible-hamburger {
            .ms-header__divider {
                background: var(--msv-header-bg);
                border-bottom: 1px solid $msv-gray-300;
                padding: $divider-padding-m;
            }

            .ms-nav.mobile-vp {
                padding-top: $nav-padding-top-m;
            }
        }
    }

    .ms-header__preferred-store-text {
        color: var(--msv-header-font-color);
        font-size: var(--msv-header-font-size);
        // margin: 0 8px;
    }

    .ms-header__preferred-store-btn {
        @include add-icon($msv-Shop-Address);
        font-size: 20px; /* peruvian customization */

        &::before {
            content: unset;
        }
    }

    .ms-header__preferred-store-btn,
    .ms-search__icon,
    .ms-header__signin-button,
    .msc-wishlist-icon,
    .msc-cart-icon,
    .ms-header__profile-button {
        background-color: transparent;
        color: var(--msv-header-font-color);

        @include font-content(var(--msv-font-weight-normal), var(--msv-header-font-size), $msv-line-height-m);
        justify-content: center;

        &:focus {
            outline-offset: -1px;
        }
    }

    // peruvian customization
    .ms-header__icons__row {
        justify-content: center;
        align-items: center;
    }

    .phone-number-header-link {
        margin-bottom: 0px;
    }

    .ms-header__preferred-store-btn.msc-btn {
        padding: 12px 10px 13px;
    }

    .phone-number-header-link, .order-status-header-link {
        color: var(--msv-header-font-color);
        font-size: var(--msv-header-font-size);
    }

    .order-status-header-link a {
        align-items: center;
        display: flex;
        font-size: 16px;
        line-height: 20px;
        padding: 0 10px;
        height: 48px;
        text-transform: capitalize;
        color: #58585b;
    }

    .ms-search__form-cancelSearch {
        color: var(--msv-header-font-color);

        @include font-content(var(--msv-font-weight-normal), var(--msv-header-font-size), $msv-line-height-m);
        justify-content: center;
    }

    .ms-search__form-control,
    .ms-search__form-cancelSearch {
        &:focus {
            outline-offset: -1px;
        }
    }

    // hiding the header icons from login page
    &.hide-header-options {
        .ms-header__nav-icon,
        .ms-header__account-info.account-desktop,
        .ms-header__account-info.account-mobile,
        .msc-cart-icon,
        .ms-header__divider,
        .msc-wishlist-icon {
            display: none;
        }
    }

    .msc-btn {
        height: 48px;
        border: none;
    }

    .ms-header__topbar {
        align-items: center;
        display: flex;
        flex-wrap: nowrap;
        height: $header-mobile-height;
        padding-bottom: 25px; /* peruvian customization */

        // peruvian customization
        .ms-header__topbar__row {
            width: 100%;
            align-items: unset;
            justify-content: end;
        }

        // peruvian customization
        .ms-header__bottombar__row {
            align-items: end;

            .ms-header__search__col-4 {
                .ms-search__form-submitSearch.msc-btn {
                    height: 25px;
                }
            }

            .ms-header__cart__col-4 {
                display: flex;
                justify-content: flex-end;
            }
        }
    }

    .ms-header__desktop-view {
        display: none;
    }

    .ms-header__phone {
        align-items: center;
        display: flex;
        font-size: 16px;
        line-height: 20px;
        padding: 0 10px;
        height: 48px;
        color: #58585b;

        a {
            color: #58585b;
        }
    }

    .ms-header__account-info {
        @include font-content(var(--msv-font-weight-normal), var(--msv-header-font-size), $msv-line-height-m);
        display: none;
        height: $header-height;
        justify-content: flex-end;
        margin-left: $signin-info-spacing-left;

        &__account-link-container {
            height: $account-link-height;
        }

        /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
        a {
            align-items: center;
            display: flex;
            font-size: $msv-text-size;
            line-height: $msv-text-line-height;
            padding: $signin-link-padding;
            height: $signin-link-height;
            color: #58585b;
            border-bottom: solid 1px #58585b;

            &:hover {
                text-decoration: none;
            }

            &:last-child {
                border-bottom: none;
            }
        }

        .ms-header__signin-button {
            background-color: var(--msv-header-bg);
            color: var(--msv-header-font-color);
            font-weight: var(--msv-font-weight-normal);

            &:focus {
                outline-offset: -1px;
            }

            &::before {
                content: unset;
            }
        }

        .msc-popover {
            background-color: var(--msv-header-bg);
            background-clip: padding-box;
            border: 1px solid $msv-gray-500;
            display: block;
            left: 65px !important;
            max-width: $account-links-max-width;
            position: absolute;
            top: 0;
            word-wrap: break-word;
            z-index: 1060;
            box-shadow: -3px 8px 10px $msv-box-shadow-color;
            width: 200px;

            .msc-btn {
                color: var(--msv-header-font-color);
                font-weight: var(--msv-font-weight-normal);
                height: $popover-menu-item-height;
            }

            .msc-arrow {
                display: block;
                height: 0.5rem;
                margin: 0 0.3rem;
                position: absolute;
                top: calc((0.5rem + 1px) * -1);
                width: 1rem;
                left: 15px !important;

                &::before,
                &::after {
                    border-color: transparent;
                    border-style: solid;
                    border-width: 0 0.5rem 0.5rem 0.5rem;
                    content: "";
                    display: block;
                    position: absolute;
                }

                &::before {
                    border-bottom-color: $msv-gray-500;
                    top: 0;
                }

                &::after {
                    border-bottom-color: var(--msv-border-color);
                    top: 1px;
                }
            }
        }
    }

    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
    .msc-wishlist-icon {
        &:hover {
            text-decoration: none;
        }
    }

    .ms-header__wishlist-desktop {
        align-items: center;
        display: none;
        height: $header-height;
        width: $header-icon-width;

        @include add-icon($msv-Heart, before, 400);

        &::before {
            font-size: 20px; /* peruvian customization */
            text-align: center;
            vertical-align: text-bottom;
            width: $header-icon-width;
        }

        .msc-wishlist-icon__text {
            display: none;
        }
    }

    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
    .ms-header__nav-icon {
        align-items: center;
        background: var(--msv-header-bg);
        display: flex;
        height: $header-height;
        justify-content: center;
        width: $header-icon-width;
        margin: 0 auto; /* peruvian customization */

        &:focus {
            outline-offset: -1px;
        }

        &::before {
            @include msv-icon();
            content: $msv-GlobalNavButton;
            font-size: 20px; /* peruvian customization */
            width: $header-icon-width;
            vertical-align: text-bottom;
            text-align: center;
            color: #58585b;
        }

        &:hover {
            text-decoration: none;
        }
    }

    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
    .msc-cart-icon {
        width: $header-icon-width;
        height: $header-height;
        display: flex;
        align-items: center;

        &::before {
            @include msv-icon();
            content: $msv-shopping-bag;
            font-size: 20px; /* peruvian customization */
            width: $header-icon-width;
            vertical-align: text-bottom;
            text-align: center;
        }

        &:hover {
            text-decoration: none;
        }

        &__count {
            @include font-content-s();
            margin-left: $header-cart-icon-margin-left;
            font-size: 20px; /* peruvian customization */
        }
    }

    // 0px
    @media (min-width: $msv-breakpoint-xs) {
        .ms-nav.desktop-vp {
            display: none;
        }

        .msc-cart-icon {
            &::before {
                width: $header-icon-width / 2;
            }
        }
    }

    // 1200px
    // peruvian customization
    @media (max-width: $msv-breakpoint-xl-peruvian) {
        .ms-header {
            &__preferred-store-btn {
                padding: 0 20px;
            }
        }
        .ms-header__container .ms-header__topbar{
            align-items: center;
        }
    }
    
    // 992px
    @media (max-width: $msv-breakpoint-l) {
        padding-left: $header-container-spacing-mobile-left;
        padding-right: $header-container-spacing-mobile-right;
        background-position: 0px 200%; /* peruvian customization */

        .msc-wishlist-icon {
            @include add-icon($msv-Heart, before, 400);

            &::before {
                padding-right: $header-icon-padding-right;
            }
        }

        &__container {
            .ms-header__topbar {
                justify-content: space-between;

                .ms-header__topbar__row {
                    .ms-header__preferred-store-container, .order-status-header-link, .phone-number-header-link, .ms-header__phone {
                        display: none;
                    }
                }

                .ms-header__bottombar__row {

                    align-items: start;
        
                    .ms-header__search__col-4 {
                        display: flex;
                        flex: 0 0 20%;
                        max-width: 20%;

                        .ms-search.with-submit {
                            position:absolute;
                            bottom: -125px;
                            min-width: 350px;
                        }

                        .ms-header__nav-icon {
                            margin: unset;
                            padding-left: 0px;
                        }
                    }
                    
                    .ms-header__logo__col-4 {
                        flex: 0 0 60%;
                        max-width: 60%;
                    }

                    .ms-header__cart__col-4 {
                        flex: 0 0 20%;
                        max-width: 20%;
                    }
                }

                // peruvian customization - removed ms-header__logo margin

                .ms-site-picker {
                    display: none;
                }
            }

            .ms-header__collapsible-hamburger {
                .ms-nav {
                    .ms-nav__drawer {
                        .drawer__button {
                            @include font-content(var(--msv-font-weight-normal), var(--msv-header-font-size), $msv-line-height-m);
                            color: var(--msv-header-font-color);
                            background: transparent;
                            text-align: left;
                            width: 100%;

                            .ms-nav__drawer-open {
                                @include add-icon($msv-pc-minus, after);

                                &::after {
                                    font-variant: all-petite-caps; 
                                }
                            }

                            .ms-nav__drawer-close {
                                @include add-icon($msv-pc-plus, after);

                                &::after {
                                    font-variant: all-petite-caps; 
                                }
                            }

                            .drawer__glyph__end {
                                position: absolute;
                                right: 10px;
                            }
                        }

                        .msc-btn {
                            outline-offset: -1px;
                        }
                    }

                    .ms-nav__list__item__link {
                        @include font-content(var(--msv-font-weight-normal), var(--msv-header-font-size), $msv-line-height-m);
                        align-items: center;
                        display: flex;
                        padding: $header-list-item-link-padding;
                    }
                }
            }
        }

        .ms-header__account-info.account-mobile {
            display: unset;
            margin-left: 0;

            /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
            .drawer__button {
                @include font-content(var(--msv-font-weight-normal), var(--msv-header-font-size), $msv-line-height-m);
                @include add-icon($msv-Contact, before, 400);

                &::before {
                    padding-right: $header-icon-padding-right;
                }
                background: transparent;
                text-align: left;
                width: 100%;

                .ms-header__drawer-open {
                    @include add-icon($msv-pc-minus, after);

                    &::after {
                        font-variant: all-petite-caps; 
                    }
                }

                .ms-header__drawer-close {
                    @include add-icon($msv-pc-plus, after);

                    &::after {
                        font-variant: all-petite-caps; 
                    }
                }

                /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                .drawer__glyph__end {
                    position: absolute;
                    right: $header-icon-padding-right;
                }
            }

            .ms-header__signin-button {
                display: flex;
                justify-content: flex-start;

                @include add-icon($msv-Contact, before, 400);

                &::before {
                    content: unset;
                }
            }

            /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
            .msc-btn {
                outline-offset: -1px;
            }
        }

        .ms-header__collapsible-hamburger {
            background: var(--msv-header-bg);
            left: 0;
            margin-top: 2px;
            position: absolute;
            width: 100%;
            z-index: 1012;

            .ms-header__wishlist-mobile {
                text-align: left;
                width: 100%;
            }

            .ms-search {
                justify-content: flex-start;
                display: flex;

                .ms-search__label {
                    display: flex;
                    width: 100%;

                    .ms-search__icon {
                        display: flex;
                        justify-content: flex-start;
                        width: 100%;
                    }

                    .ms-search__icon-text {
                        display: unset;
                        padding-left: $header-icon-padding-left;
                    }
                }
            }
        }
    }

    // 768px
    @media (max-width: $msv-breakpoint-m) {
        background-position: 0 275%; /* peruvian customization */
    }

    @media (max-width: 360px) {

        background-position: 0 340%; /* peruvian customization */

        &__container {
            .ms-header__topbar {

                .ms-header__bottombar__row {
        
                    .ms-header__search__col-4 {

                        .ms-search.with-submit {
                            bottom: -145px;
                            min-width: 335px;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 320px) {

        background-position: 0 340%; /* peruvian customization */

        &__container {
            .ms-header__topbar {

                .ms-header__bottombar__row {
        
                    .ms-header__search__col-4 {

                        .ms-search.with-submit {
                            bottom: -105px;
                            min-width: 290px;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 280px) {

        background-position: 0 475%; /* peruvian customization */

        &__container {
            .ms-header__topbar {

                .ms-header__bottombar__row {
        
                    .ms-header__search__col-4 {

                        .ms-search.with-submit {
                            bottom: -65px;
                            min-width: 252px;
                        }
                    }
                }
            }
        }
    }

    @media (min-width: 411px) {
        &__container {
            .ms-header__topbar {

                .ms-header__bottombar__row {
        
                    .ms-header__search__col-4 {

                        .ms-search.with-submit {
                            bottom: -160px;
                            min-width: 390px;
                        }
                    }
                }
            }
        }
    }

    // 568px
    @media (min-width: 568px) {
        background-position: 0 205%; /* peruvian customization */
    }

    // 786px and 1024px
    @media (min-width: $msv-breakpoint-m) and (max-width: 1024px) {
        background-position: 0 200%; /* peruvian customization */

        &__container {
            .ms-header__topbar {

                .ms-header__bottombar__row {
        
                    .ms-header__search__col-4 {

                        .ms-search.with-submit {
                            bottom: -70px;
                        }
                    }
                }
            }
        }
    }

    // 992px
    @media (min-width: $msv-breakpoint-l) {
        // padding-left: $header-container-spacing-desktop;
        // padding-right: $header-container-spacing-desktop;
        background-position: 0 110%; /* peruvian customization */
        padding-top: unset;

        .ms-header__desktop-view {
            display: block;
        }

        .ms-nav.desktop-vp {
            display: flex;
            justify-content: center;
        }

        &__modal {
            display: none;
        }

        &__profile-button {
            color: var(--msv-header-font-color);
            font-weight: var(--msv-font-weight-normal);
            background: var(--msv-header-bg);
        }

        &__divider {
            background: $msv-divider-color;
            height: $divider-margin-height;
            // margin-left: $divider-margin-left;
            // margin-right: $divider-margin-right;
            width: $divider-margin-width;
        }

        .ms-header__account-info.account-desktop {
            display: block;
        }

        .ms-header__collapsible-hamburger {
            display: none;

            .ms-header__wishlist-mobile {
                text-align: left;
                width: 100%;
            }
        }

        .ms-search {
            display: flex;

            &__icon {
                align-items: center;
                color: var(--msv-header-font-color);
                font-weight: normal;

                &:focus {
                    outline-offset: -1px;
                }
            }
        }

        .ms-header__wishlist-desktop {
            display: flex;
        }

        .ms-header__nav-icon {
            display: none;
        }

        .ms-cart-icon {
            // padding-left: $cart-icon-spacing-left;
            padding: 0px 10px;
            width: unset;
            // position: relative;

            &::before {
                width: unset;
                margin-right: 4px;
            }
        }
    }

    // 1200px
    // peruvian customization
    @media (min-width: $msv-breakpoint-xl-peruvian){
        background-position: 0 110%;

        .ms-header__topbar{
            padding-bottom: 25px;
        }
    }

    // 1440px
    // peruvian customization
    @media (min-width: $msv-breakpoint-xl){
        background-position: 0 110%;
    }

    // 1600px
    // peruvian customization
    @media (min-width: $msv-breakpoint-xxl) {
        .ms-header__topbar{
            padding: 0 100px;
            padding-bottom: 25px;
        }
    }
}
