$quickView-top: 240px;
$quickView-left: 5px;
$quickView-right: 24px;
$quickView-label-margin-bottom: 5px;
$quickView-box-top-margin: 20px;
$quickview-padding: 12px 15px;
$quickView-box-bottom-margin: 20px;
$quickView-box-top-margin: 20px;
$quickView-button-padding: 5px;
$quickView-description-margin-left: 30px;
$quickView-description-margin-top: 274px;
$quickview-product-title-bottom: 200px;
$quickview-product-title-height: 63px;
$quickView-add-to-cart-min-width: 290px;
$quickView-add-to-cart-min-height: 48px;
$quickView-add-to-cart-extra-actions-margin: 8px;
$quickView-add-to-cart-container-margin-top: 40px;
$quickView-add-to-cart-container-margin-right: 12px;
$quickView-add-to-cart-extra-actions-width: 48px;
$quickView-product-status-padding-top: 18px;
$quickView-quantity-input: 5px;
$quickView-quantity-input-margin-left: 8px;
$quickView-quantity-input-margin-right: 8px;
$quickView-incr-decr: 5px;
$media-gallery-width: 375px;
$media-gallery-height: 700px;
$msv-dialog-width: 778px;
$msv-dialog-height: 607px;
$msv-dialog-modal-content-padding: 20px;
$msv-dialog-modal-content-body-text-padding: 4px 0;
$msv-dialog-modal-content-empty-image-padding-bottom: 10px;
$msv-dialog-button-width: 100%;
$msv-quickView-rating-star-bottom: 220px;
$msv-quickView-rating-star-left: 77px;
$msv-quickView-price-bottom: 210px;
$msv-quickView-price-left: 80px;
$msv-icon-margin-right: 8px;
$quickView-custom-amount-input-width: 80px;
$quickView-custom-amount-input-padding: 5px;
$quickView-quantity-alert-msg-width: 290px;

//style presets
:root {
    --msv-quickView-btn-bg: #{$msv-white};
    --msv-quickView-btn-border-color: #{$msv-gray-300};
    --msv-quickView-btn-font-size: var(--msv-body-font-size-l);
    --msv-quickView-incdec-btn-font-color: var(--msv-font-primary-color);

    //title
    --msv-quickView-title-font-size: var(--msv-body-font-size-xl);
    --msv-quickView-title-font-color: var(--msv-font-primary-color);

    // rating star
    --msv-quickView-rating-star: var(--msv-font-primary-color);
    --msv-quickView-btn-extra-actions-color: var(--msv-accent-brand-color);

    // wishlist button
    --msv-quickview-btn-wishlist-color: var(--msv-accent-brand-color);

    //text
    --msv-quickView-text-font-size: var(--msv-body-font-size-m);
    --msv-quickView-text-font-color: var(--msv-font-primary-color);

    // primary button
    --msv-quickView-primary-btn-bg: var(--msv-accent-brand-color);
    --msv-quickView-primary-btn-font-color: var(--msv-font-secondary-color);
    --msv-quickView-primary-btn-border: var(--msv-accent-brand-color);

    // secondary button
    --msv-quickView-secondary-btn-bg: var(--msv-secondary-button-background-color);
    --msv-quickView-secondary-btn-font-color: var(--msv-font-primary-color);
    --msv-quickView-secondary-btn-border: var(--msv-accent-brand-color);

    // Indicators
    --msv-carousel-indicator-fill-color: #{$msv-gray-800};
    --msv-carousel-indicator-active-fill-color: #{$msv-gray-900};
    --msv-carousel-indicator-border-color: var(--msv-font-secondary-color);

    // Flippers
    --msv-carousel-flipper-color: #{$msv-gray-900};
    --msv-carousel-flipper-hover-color: #{$msv-gray-800};
}

.ms-quickView {
    display: flex;
    flex-flow: row;
    /* left: 5px; */
    overflow: hidden;
    /* padding: 12px 15px; */
    position: absolute;
    /* right: 24px; */
    text-align: center;
    top: 35%;
    width: 100%;

    &__content {
        width: 50%;

        @media (max-width: $msv-breakpoint-m) {
            width: 90%;
        }

        .msc-rating {
            padding-top: 16px;
        }

        .bv-rating_summary {
            margin: 10px 0px;
        }
    }

    &__dialog {
        @media (max-width: $msv-breakpoint-m) {
            .msc-modal__content {
                height: 710px;
                width: 100%;
            }
        }
    }

    &__loading {
        &__icon {
            @include add-icon($msv-Spinner, after);
            border-radius: 55px;
            display: flex;
            font-size: 5rem;
            justify-content: center;
            margin: 1rem auto;
            padding: 15px;

            &::after {
                -webkit-animation: spin 1s steps(8) infinite;
                animation: spin 1s steps(8) infinite;
            }
        }

        &__msg {
            margin-bottom: 15rem;
            text-align: center;
        }
    }

    &__header {
        margin-bottom: 0;
    }

    &__button {
        @include button-default();
        @include secondary-button();
        @include font-content(var(--msv-font-weight-light), $msv-font-size-m, $msv-line-height-s);

        cursor: pointer;
        display: none;
        flex-grow: 1;
        // height: 30px;
        padding: $quickView-button-padding;
        // width: 100%;
        // position: absolute;
        // top: 33%;
        border: none;
        opacity: 0.85;
        // height: 40px;
    }

    &__seeDetailsbutton {
        cursor: pointer;
        line-height: unset;
        padding: 20px 0;
        text-decoration: underline;
        color: #58585b;
    }

    &__product-add-to-cart {
        left: 376px;
        position: relative;

        @media (max-width: $msv-breakpoint-m) {
            top: 60px;
            left: 0;
        }
    }

    &__add-to-cart-container {
        border-radius: 2px;
        display: inline-block;
        margin-top: $quickView-add-to-cart-container-margin-top;
        margin-right: $quickView-add-to-cart-container-margin-right;
        min-width: $quickView-add-to-cart-min-width;
        min-height: $quickView-add-to-cart-min-height;
        position: relative;

        .msc-alert-danger {
            width: $quickView-quantity-alert-msg-width;
        }

        .msc-add-to-cart {
            border-radius: 2px;
            min-width: $quickView-add-to-cart-min-width;
            min-height: $quickView-add-to-cart-min-height;
            position: relative;

            @media (max-width: $msv-breakpoint-m) {
                left: 27px;
                min-width: 205px;
            }
        }
    }

    &__body {
        display: flex;
        flex-flow: row;
        padding: 0 20px;
        max-height: unset !important;

        @media (max-width: $msv-breakpoint-m) {
            flex-flow: column;
        }

        .msc-rating {
            &__count,
            &__star {
                color: var(--msv-quickView-rating-star);
                margin-top: 5px;
            }

            &__half-star {
                &::after {
                    color: var(--msv-quickView-rating-star);
                }
            }
        }

        @media (max-width: $msv-breakpoint-m) {
            .msc-rating {
                &__count,
                &__group {
                    bottom: $msv-quickView-rating-star-bottom;
                    left: $msv-quickView-rating-star-left;
                    position: relative;
                }
            }
        }

        .msc-price__actual {
            @include font-content-xl();
            color: var(--msv-quickView-text-font-color);

            @media (max-width: $msv-breakpoint-m) {
                bottom: $msv-quickView-price-bottom;
                left: $msv-quickView-price-left;
                position: relative;
            }
        }

        .msc-price__strikethrough {
            @include font-content-xl(var(--msv-font-weight-light));

            @media (max-width: $msv-breakpoint-m) {
                bottom: $msv-quickView-price-bottom;
                left: $msv-quickView-price-left;
                position: relative;
            }
        }

        .ms-quickView__key_in_price {
            margin-top: 20px;

            .ms-quickView__key_in_price-label {
                @include font-content-l(var(--msv-font-weight-light));
                color: var(--msv-quickView-title-font-color);
                display: block;
                margin-bottom: $quickView-label-margin-bottom;
            }

            .ms-quickView__key_in_price_custom-amount__input {
                @include form-control();
                -moz-appearance: textfield;
                padding: $quickView-custom-amount-input-padding;
                text-align: left;

                &::-webkit-inner-spin-button,
                &::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                }
            }
        }

        .ms-text-block {
            margin-top: 0;
            margin-bottom: 1rem;

            @include font-content(
                var(--msv-font-weight-normal),
                var(--msv-quickView-text-font-size),
                $msv-line-height-m
            );
        }

        .product-add-to-cart {
            margin-top: 20px;

            .buttons {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
            }

            .msc-add-to-cart {
                @include primary-button(
                    var(--msv-quickView-primary-btn-bg),
                    var(--msv-quickView-primary-btn-font-color),
                    var(--msv-quickView-primary-btn-border)
                );
                font-size: var(--msv-quickView-btn-font-size);
                flex-grow: 1;
                line-height: $msv-line-height-l;
            }

            .msc-add-to-cart-extra-actions {
                @include font-glyph-standard();
                @include secondary-button(
                    var(--msv-quickView-secondary-btn-bg),
                    var(--msv-quickView-secondary-btn-font-color),
                    var(--msv-quickView-secondary-btn-border)
                );
                color: var(--msv-quickView-btn-extra-actions-color);
                flex: 0 0 $quickView-add-to-cart-extra-actions-width;
                min-width: $quickView-add-to-cart-extra-actions-width;
                margin-left: $quickView-add-to-cart-extra-actions-margin;
                padding-left: 0;
                padding-right: 0;
                position: relative;
                width: $quickView-add-to-cart-extra-actions-width;

                &:not(:disabled):hover,
                &:not(:disabled):not(.disabled):active:focus {
                    border-color: transparent;
                    color: var(--msv-quickView-btn-extra-actions-color);
                }
            }
        }

        .msc-dropdown__select {
            @include form-control();
            height: 50px;
            width: 100%;

            @media (max-width: $msv-breakpoint-m) {
                width: 95%;
            }
        }

        .quantity {
            margin-top: 8px;

            .quantity-input {
                @include form-control();
                display: inline-block;
                margin-left: $quickView-quantity-input-margin-left;
                margin-right: $quickView-quantity-input-margin-right;
                -moz-appearance: textfield;
                padding: $quickView-quantity-input;
                vertical-align: bottom;
                width: 230px;

                @media (max-width: $msv-breakpoint-m) {
                    width: 150px;
                }

                @media (max-width: $msv-breakpoint-l) {
                    width: 156px;
                }

                &::-webkit-inner-spin-button,
                &::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                }

                &:disabled {
                    /* stylelint-disable-next-line declaration-no-important -- Auto-suppressed. Please fix if possible. */
                    background-color: #ffffff !important;
                    /* stylelint-disable-next-line declaration-no-important -- Auto-suppressed. Please fix if possible. */
                    border: 1px solid #d1d1d1 !important;
                }
            }

            .quantity__controls {
                background: var(--msv-quickView-btn-bg);
                background-position: center;
                background-repeat: no-repeat;
                border: 1px solid var(--msv-quickView-btn-border-color);
                cursor: pointer;
                height: 48px;
                margin-right: 4px;
                min-width: auto;
                padding: $quickView-incr-decr;
                padding-left: 0;
                padding-right: 0;
                width: 48px;

                &:disabled {
                    color: $msv-gray-300;
                }
            }

            .increment {
                @include add-icon($msv-Add, after);
                color: var(--msv-quickView-incdec-btn-font-color);

                &.disabled {
                    color: $msv-gray-300;
                }

                &:not(:disabled):not(.disabled):active,
                &:not(:disabled):not(.disabled):active:focus {
                    color: var(--msv-quickView-incdec-btn-font-color);
                }
            }

            .decrement {
                @include add-icon($msv-Remove, after);
                color: var(--msv-quickView-incdec-btn-font-color);

                &.disabled {
                    color: $msv-gray-300;
                }

                &:not(:disabled):not(.disabled):active,
                &:not(:disabled):not(.disabled):active:focus {
                    color: var(--msv-quickView-incdec-btn-font-color);
                }
            }
        }
    }

    &__inventory-info {
        margin-top: $quickView-box-top-margin;
    }

    &__product-title {
        @include font-content($msv-font-weight-normal, var(--msv-quickView-title-font-size), $msv-line-height-xl);
        color: var(--msv-quickView-title-font-color);
        margin-bottom: 8px;

        @media (max-width: $msv-breakpoint-m) {
            bottom: $quickview-product-title-bottom;
            display: flex;
            flex-flow: row;
            height: $quickview-product-title-height;
            margin-top: -20px;
            margin-bottom: 10px;
            position: relative;
            text-align: center;
        }
    }

    &__inventory-label {
        @include font-content(var(--msv-font-weight-normal), var(--msv-body-font-size-l), $msv-line-height-m);
    }

    &__product-description {
        @include font-content(var(--msv-font-weight-light), var(--msv-quickView-text-font-size), $msv-line-height-m);
        color: var(--msv-quickView-text-font-color);
        margin-bottom: $quickView-box-bottom-margin;
        margin-top: $quickView-box-top-margin;

        @media (max-width: $msv-breakpoint-m) {
            margin-left: $quickView-description-margin-left;
            position: relative;
            top: $quickView-description-margin-top;
        }
    }

    &__configure {
        @include font-content-l(var(--msv-font-weight-normal));

        @media (max-width: $msv-breakpoint-m) {
            bottom: 50px;
        }
    }

    &__dropdown {
        margin-top: 20px;

        &-quantity-label {
            @include font-content-l(var(--msv-font-weight-light));
            color: var(--msv-quickView-title-font-color);
        }

        .msc-swatch-container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            img {
                border-radius: 50%;
                width: 35px;
                height: 35px;
                margin: 5px;
                cursor: pointer;
            }

            button {
                width: 48px;
                height: 48px;
                margin: 5px;
                background-color: transparent;
                cursor: pointer;
                // display: none;
            }

            button:first-child {
                margin-left: 0px;
            }

            img:first-child {
                margin-left: 0px;
            }
        }
    }

    &__media-gallery {
        display: block;
        flex-grow: 1;
        flex-wrap: nowrap;
        margin-right: 40px;

        &__carousel,
        .msc-carousel {
            background-color: $msv-white;
            height: 400px;
            width: 310px;

            &__indicators {
                bottom: 20px;

                div {
                    display: none;
                }

                li {
                    background: var(--msv-carousel-indicator-fill-color);
                    height: 8px;
                    margin-left: 4px;
                    margin-right: 4px;
                    width: 8px;
                }

                .active {
                    background: var(--msv-carousel-indicator-active-fill-color);
                }
            }

            &__control {
                &__prev,
                &__next {
                    background-color: transparent;
                    border: none;
                    bottom: 220px;
                    color: var(--msv-carousel-flipper-color);
                    height: auto;
                    top: auto;
                    width: auto;

                    &:hover {
                        color: var(--msv-carousel-flipper-color);
                    }
                }

                &__prev__icon {
                    @include add-icon($msv-ChevronLeft);
                    margin-left: 0;
                }

                &__next__icon {
                    @include add-icon($msv-ChevronRight);
                    margin-right: 0;
                }
            }
        }

        .ms-media-gallery__thumbnails-container {
            display: none;
            height: 100px;
            width: fit-content;

            .msc-ss-carousel {
                bottom: 426px;
                height: fit-content;
                width: fit-content;
            }

            .msc-ss-carousel-slide {
                display: block;
            }

            .ms-media-gallery__thumbnail-item:not(:last-child) {
                margin-bottom: 10px;
                margin-right: 20px;
            }

            .ms-media-gallery__carousel {
                margin-bottom: 0;
            }
        }
    }

    &__add-to-wishlist-container {
        display: inline-block;
        position: relative;
        right: 304px;

        .msc-add-to-wishlist {
            color: var(--msv-quickview-btn-wishlist-color);
            height: 48px;
            left: 300px;
            margin: 0;
            position: relative;
            width: 48px;
            color: #58585b;
            background-color: transparent;
            border: none;

            @media (max-width: $msv-breakpoint-m) {
                left: 236px;
                top: 0;
                width: 44px;
            }
        }

        .msc-add-to-wishlist:after {
            font-size: 1.5em;
        }

        .ms-wishlist-items__product-status {
            display: inline-block;
            position: relative;
            right: 350px;
            top: 44px;
            width: 254px;

            @include add-icon($msv-Checkbox-Checked, before);
            cursor: pointer;
            outline: none;

            &::before {
                bottom: 12px;
                position: relative;
            }

            &:hover {
                text-decoration: underline;
            }

            .msc-alert {
                background-color: transparent;
                border: none;
                bottom: 45px;
            }

            .msc-alert button.close {
                display: none;
            }

            @media (max-width: $msv-breakpoint-m) {
                left: -194px;
                top: 40px;
                width: 220px;
            }
        }
    }

    &__quantity {
        margin-top: 13px;
    }

    &__product-quantity-label {
        @include font-content-l(var(--msv-font-weight-light));
        color: var(--msv-quickView-title-font-color);
        display: block;
        margin-bottom: $quickView-label-margin-bottom;
    }

    //mobile vp

    @media (max-width: $msv-breakpoint-m) {
        display: none;
        flex-flow: column;
        // top: 60%;

        &__media-gallery {
            display: block;
            flex-grow: 1;
            flex-wrap: nowrap;

            &__carousel,
            .msc-carousel {
                left: 16px;
                height: 236px;
                top: 96px;
                width: 220px;

                &__indicators {
                    bottom: -10px;

                    div {
                        display: none;
                    }
                }

                &__control {
                    &__prev,
                    &__next {
                        bottom: 120px;
                        height: auto;
                        top: auto;
                        width: auto;
                    }

                    &__prev__icon {
                        @include add-icon($msv-ChevronLeft);
                        margin-left: 0;
                    }

                    &__next__icon {
                        @include add-icon($msv-ChevronRight);
                        margin-right: 0;
                    }
                }
            }

            .ms-media-gallery__thumbnails-container {
                width: fit-content;
                height: fit-content;

                .msc-ss-carousel {
                    height: fit-content;
                    left: 21px;
                    top: 248px;
                    width: fit-content;

                    .msc-flipper {
                        display: none;
                    }
                }

                .msc-ss-carousel-slide {
                    display: flex;
                }

                .ms-media-gallery__thumbnail-item img {
                    height: 40px;
                    margin-right: -8px;
                    margin-left: 4px;
                    width: 40px;
                }

                .ms-media-gallery__carousel {
                    margin-bottom: 0;
                }
            }
        }
    }
}

// Dimensions CSS Update
.ms-quickView__dialog {
    .ms-buybox__dropdown-quantity-label {
        display: block;
    }
    .msc-swatch__wrapper {
        display: inline-block;
    }
}
