$footer-ms-footer-padding-xl: 48px 0;
$footer-ms-footer-margin-left-mobile: 0;
$footer-category-max-width: 200px;
$footer-category-padding-bottom: 48px;
$footer-item-padding-desktop: 5px 0;
$footer-item-padding-right-mobile: 28px;
$footer-heading-title-line-height: 24px;
$footer-heading-title-margin-bottom: 0.5rem;
$footer-list-item-icon-left: 10px;
$footer-list-item-icon-number: 3;
$footer-list-item-icon-top: 40px;
$footer-row-padding-top-mobile: 32px;
$footer-back-to-top-line-height: 24px;
$footer-back-to-top-height: 48px;
$footer-back-to-top-width: 48px;
$footer-back-to-top-right: 24px;
$footer-back-to-top-bottom: 24px;
$footer-back-to-top-border-radius: 2px;
$footer-categoty-back-to-top-max-width: 0;

:root {
    --msv-footer-bg: #{$msv-peruvian-winter}; /* peruvian customization */
    // Headings
    --msv-footer-heading-font-color: #{$msv-white}; /* peruvian customization */
    --msv-footer-heading-font-size: var(--msv-body-font-size-peruvian-l); /* peruvian customization */

    //Links
    --msv-footer-link-font-color: #{$msv-white}; /* peruvian customization */
    --msv-footer-link-font-size: var(--msv-body-font-size-m);
}

footer {
    background-color: var(--msv-footer-bg);
    // peruvian customization - white stitch
    background: url(https://images-us-prod.cms.commerce.dynamics.com/cms/api/pdzhjcgxml/imageFileData/MA1aQa?ver=d2d5) #54301A repeat-x;
    background-position: 0px 2.5%;
    // peruvian customization - gray stitch - change color after link for footer background
    // background: url(https://images-us-prod.cms.commerce.dynamics.com/cms/api/pdzhjcgxml/imageFileData/MA1drs?ver=6c54) #54301A repeat-x;
    // background-position: 0px 2.5%;

    .ms-footer {
        &__list {
            flex: initial;
            width: auto;
        }

        // peruvian customization
        div.col-12:first-child{
            width: 100%;
            max-width: unset;
            text-align: center;
        }

        // peruvian customization
        div.col-12:nth-child(6){
            width: 100%;
            max-width: unset;
            text-align: center;
            padding-bottom: 75px;

            .ms-footer__list{
                width: 70%;
                margin: auto;

                li{

                    .ms-footer__heading__title{
                        margin-top: 10%;
                        text-decoration: none;
                    }

                    &:first-child{
                        .ms-footer__heading__title{
                            font-size: 1em;
                            margin-top: 0;
                        }
                    }
                }
            }
        }

        // peruvian customization
        div.col-12:nth-child(7){
            width: 100%;
            max-width: unset;
            text-align: center;
            padding-bottom: unset;

            img{
                // object-fit: contain;
                height: auto;
            }
        }

        // peruvian customization
        div.col-12:nth-child(8) {
            position: absolute;
        }

        // peruvian customization
        .footer-links-left-index {
            display: flex;
            justify-content: center;
        }

        // peruvian customization
        .footer-links-right-locations {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
        }

        .ms-back-to-top {
            @include vfi();
            background: var(--msv-bg-color);
            border: 1px solid var(--msv-accent-brand-color);
            border-radius: $footer-back-to-top-border-radius;
            bottom: $footer-back-to-top-bottom;
            cursor: pointer;
            display: none;
            height: $footer-back-to-top-height;
            min-width: auto;
            opacity: 0;
            padding: 0;
            position: fixed;
            right: $footer-back-to-top-right;
            text-align: center;
            vertical-align: middle;
            width: $footer-back-to-top-width;

            &:hover {
                background: var(--msv-accent-secondary-btn);
            }

            .fa-arrow-up {
                color: var(--msv-accent-brand-color);
                font-size: var(--msv-body-font-size-xl);
                left: 50%;
                line-height: $footer-back-to-top-line-height;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);

                &::before {
                    @include msv-icon();
                    content: $msv-ChevronUp;
                }
            }
        }

        &__item {
            // padding: $footer-item-padding-desktop; /* peruvian customization */
            width: 100%;

            // peruvian customization
            &.tagline-footer > .ms-footer__heading > .ms-footer__heading__title{
                text-decoration: none;
                font-family: 'UglyQua'; 
                font-size: 1.25em;
            }
        }

        // peruvian customization
        .index-footer a:after, .site-footer a:after, .terms-footer a:after, .privacy-footer a:after, .dmca-footer a:after, .us-footer-link a:after, .uk-footer-link a:after, .de-footer-link a:after {
            content: '';
            padding-right: 10px;
        }

        &__heading__title {
            color: var(--msv-footer-heading-font-color);
            font-size: var(--msv-footer-heading-font-size);
            // peruvian customization - removed font-weight
            line-height: $msv-line-height-l;
            margin-bottom: $footer-heading-title-margin-bottom;
            text-decoration: underline; /* peruvian customization */
        }

        &__link {

            &:hover{
                text-decoration: none;
            }

            @include vfi();
            display: inline-flex;

            img {
                @include vfi();
            }

            div, &__text {
                color: var(--msv-footer-link-font-color);
                font-size: var(--msv-footer-link-font-size);
                font-weight: var(--msv-font-weight-normal);
                line-height: $msv-line-height-m;
            }
        }

        & > * {
            justify-content: center; /* peruvian customization */ 
            margin-left: auto;
            margin-right: auto;
            padding: $footer-ms-footer-padding-xl;
            // width: 80%; /* peruvian customization */

            > * {
                max-width: $footer-category-max-width;
                padding-bottom: $footer-category-padding-bottom;

                &:nth-child(6) { /* peruvian customization - updated from 4*/
                    .ms-footer__list {
                        display: flex;
                        width: auto;
                    }

                    .ms-footer__list-item:first-child {
                        position: absolute;
                        width: 100%;
                    }

                    .ms-footer__list-item:nth-child(1) {
                        position: absolute;
                        width: 100%;
                        left: 0%;
                        font-size: 1.25em;
                        text-decoration: none;
                    }

                    .ms-footer__list-item:nth-child(2) {
                        position: relative;
                        top: $footer-list-item-icon-top;
                        width: 100%;
                        margin-top: 15px;
                    }

                    @for $i from 3 through 7 { /* peruvian customization - updated from 2*/
                        .ms-footer__list-item:nth-child(#{$i}) {
                            // left: $footer-list-item-icon-left * ($i - 3); /* peruvian customization - updated from 2*/
                            position: relative;
                            top: $footer-list-item-icon-top;
                            width: 100%;
                            margin-top: 15px;
                        }
                    }
                }

                // peruvian customization
                &:nth-last-child(2){
                    width: 100%;
                    max-width: unset;
                    text-align: center;
                }

                &:last-child {
                    max-width: 40%; /* peruvian customization */
                    padding-bottom: unset;
                }
            }
        }

        // peruvian customization
        .facebook-footer-icon:before {
            @include msv-icon();
            content: $msv-facebook;
            font-size: 2.5em;
            width: auto;
            color: white;
            font-family: "Font Awesome 5 Brands Regular";
            cursor: pointer;
        }

        // peruvian customization
        .pinterest-footer-icon:before {
            @include msv-icon();
            content: $msv-pinterest;
            font-size: 2.5em;
            width: auto;
            color: white;
            font-family: "Font Awesome 5 Brands Regular";
            cursor: pointer;
        }

        // peruvian customization
        .instagram-footer-icon:before {
            @include msv-icon();
            content: $msv-instagram;
            font-size: 2.5em;
            width: auto;
            color: white;
            font-family: "Font Awesome 5 Brands Regular";
            cursor: pointer;
        }

        // peruvian customization
        .youtube-footer-icon:before {
            @include msv-icon();
            content: $msv-youtube;
            font-size: 2.5em;
            width: auto;
            color: white;
            font-family: "Font Awesome 5 Brands Regular";
            cursor: pointer;
        }

        // peruvian customization
        .pc-store-footer-icon:before {
            @include msv-icon();
            content: $msv-pc-store;
            font-size: 2.5em;
            width: auto;
            color: white;
            font-family: "Font Awesome 5 Free";
            cursor: pointer;
        }

        // peruvian customization
        .pc-envelope-footer-icon:before {
            @include msv-icon();
            content: $msv-pc-envelope;
            font-size: 2.5em;
            width: auto;
            color: white;
            font-family: "Font Awesome 5 Free";
            cursor: pointer;
        }



        // peruvian customization
        .facebook-footer-icon a, .pinterest-footer-icon a, .instagram-footer-icon a, .youtube-footer-icon a, .pc-store-footer-icon a, .pc-envelope-footer-icon a {
            width: 100%;
            height: 100%;
            position: absolute;
            left: -5%;
        } 
    }

    @media (max-width: 823px){
        .footer-links-left-index {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            text-align: center;
        }
    }

    @media (max-width: $msv-breakpoint-m) {
        .ms-footer {
            margin-left: $footer-ms-footer-margin-left-mobile;

            &__item {
    
                // peruvian customization
                &.tagline-footer > .ms-footer__heading > .ms-footer__heading__title{ 
                    font-size: 1.05em;
                }
            }

            // peruvian customization
            div.col-12:nth-child(6) {
                order: -1;
                margin-top: -10%;
                
                li:first-child{
                    display: none;
                }

                li {
                    width:33.3% !important;

                    .ms-footer__heading__title{
                        font-size: .95em;
                    }
                }
            }

            // peruvian customization
            div.col-12:first-child {
                order: -1;
                padding-bottom: 0px;
            }

            .facebook-footer-icon, .pinterest-footer-icon, .instagram-footer-icon, .youtube-footer-icon, .pc-store-footer-icon, .pc-envelope-footer-icon {
                padding: 15px;
            }

            // &__item {
            //     padding-right: $footer-item-padding-right-mobile; 
            //     padding: 15px;
            // }

            // peruvian customization
            .footer-links-left-index {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                text-align: center;
            }

            // peruvian customization
            .footer-links-right-locations {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                text-align: center;
            }

            > * {
                padding-top: $footer-row-padding-top-mobile;

                > * {
                    max-width: 100%;

                    &:nth-child(6) { /* peruvian customization - updated from 4*/

                        padding-bottom: 48px;

                        .ms-footer__list {
                            display: inline-flex;
                            width: unset !important;
                            flex-wrap: wrap;

                            li {
                                &:first-child {
                                    .ms-footer__item {
                                        padding: unset;
                                    }
                                }
                            }
                        }

                        .ms-footer__list-item:nth-child(2), .ms-footer__list-item:nth-child(3), .ms-footer__list-item:nth-child(4), .ms-footer__list-item:nth-child(5), .ms-footer__list-item:nth-child(6), .ms-footer__list-item:nth-child(7) {
                            margin-top: 15px;
                        }

                        @for $i from 3 through 7 {
                            .ms-footer__list-item:nth-child(#{$i}) {
                                left: 0;
                            }
                        }
                    }

                    &:first-child {
                        .ms-footer__item {
                            padding: unset;
                        }
                    }

                    &:nth-child(7) {
                        .ms-footer__item {
                            padding: unset;
                        }
                    }

                    &:nth-last-child(2) {
                        .ms-footer__item {
                            padding: unset;
                        }
                    }

                    &:last-child {

                        max-width: unset; /* peruvian customization */

                        .ms-footer__item {
                            padding: unset;
                        }
                    }
                }
            }
        }
    }

    @media (min-width: $msv-breakpoint-m) and (max-width: 1024px){
        .ms-footer
        
            div.col-12:nth-child(6) {

                .ms-footer__list {
                    width: 100% !important;
                }
            } 
    }

    @media (min-width: $msv-breakpoint-m) {
        .ms-footer {
            .ms-back-to-top[aria-hidden="false"] {
                display: block;
                opacity: 1;
                transform: translateX(0%);
            }

            > * {
                max-width: unset;
            }

            // peruvian customization
            div.col-12:nth-child(6) {
                
                margin-top: unset;

                .ms-footer__list {
                    flex-wrap: unset;
                }

                li {
                    width: 100% !important;
                }
            }
        }
    }
}

// peruvian customization
@media (max-width: $msv-breakpoint-landscape-m) {
    footer {
        background-position: 0px .5%;
    }
}
