$ms-refine-submenu-item-rating-line-height: 28px;
$ms-refine-submenu-item-rating-padding-left: 12px;
$refine-menu-rating-margin-bottom: 10px;
$refine-menu-submenu-item-line-height: 30px;
$refine-menu-submenu-item-multi-select-line-height: 30px;

.ms-refine-submenu__list {
    border: 0;
    margin: 0;
    min-width: 0;
    padding: 0;
}

// .ms-refine-submenu__list.Size {
//     .ms-refine-submenu-item__label {
//         width: 48px;
//         height: 48px;
//         margin: 5px;
//         background-color: transparent;
//         cursor: pointer;
//         border: solid 1px #58585b;
//     }
// }

.ms-refine-submenu-item {
    display: block;
    line-height: $refine-menu-submenu-item-line-height;

    .refine-submenu-item {
        &__rating {
            padding-left: $ms-refine-submenu-item-rating-padding-left;
            display: inline-block;

            @include font-content-m(var(--msv-font-weight-light));
        }
    }

    .msc-swatch-container.ms-refine-submenu-item__swatch {
        img {
            border-radius: 50%;
            width: 50px;
            height: 50px;
            // margin: 5px;
            // padding: 2px;
            cursor: pointer;
            border: solid 1px #58585b;

            &:hover, &:focus, &:active {
                padding: 2px;
                outline: solid 0px #58585b;
            }
        }
    }

    &__label {
        @include font-content-m(var(--msv-font-weight-light));
    }

    .multi-select,
    .multi-select-checked {
        line-height: $refine-menu-submenu-item-multi-select-line-height;
    }

    .msc-rating {
        @include font-content-m(var(--msv-font-weight-light));
        margin-bottom: $refine-menu-rating-margin-bottom;
    }

    &.multi-select {
        @include add-icon($msv-Checkbox, before, 400);
    }

    &.multi-select-checked {
        @include add-icon($msv-Checkbox-Checked, before, 400);
    }

    &.single-select {
        @include add-icon($msv-RadioBtnOff, before, 400);
    }

    &.single-select-checked {
        @include add-icon($msv-RadioBtnOn, before, 400);
    }

    &.multi-select,
    &.multi-select-checked,
    &.single-select,
    &.single-select-checked {
        &::before {
            vertical-align: middle;
            font-size: var(--msv-body-font-size-xl);
            display: none;
        }
    }
}

.ms-refine-submenu__toggle_collapsed {
    @include add-icon($msv-Add, after);
}

.ms-refine-submenu__toggle_expanded {
    @include add-icon($msv-Remove, after);
    @include font-content-l();
}

.msc-choice-summary__clear-all {
    @include add-icon($msv-Cancel, after);
}

.ms-refine-submenu__range-refiner {
    .slider__labels-item {
        font-size: var(--msv-body-font-size-s);
        line-height: $msv-line-height-s;
        color: $msv-gray-900;
    }
}

.ms-refine-submenu-item[aria-checked="true"] .msc-rating__count {
    font-weight: bold;
}

ul.ms-refine-submenu__list.Color {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .ms-refine-submenu-item__label {
        display: none;
    }
}

// ul.ms-refine-submenu__list.Size {
//     display: flex;
//     flex-direction: row;
//     flex-wrap: wrap;
// }
